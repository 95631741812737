<template>
    <HeaderCard :loading="loading" tittle="SLA (Agendamentos concluídos)" @onLoad="load" />
    <AppGrafico :conjuntosDeDados="conjuntosDeDados" :dados="dados" class="h-full" keyCategoria="quebra" tipoLegenda="row" tipoIndicador="text"/>
</template>
<script>
import 'moment/locale/pt-br';
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import moment from 'moment-timezone';
import AppGrafico from '@/components/grafico/AppGrafico.vue';

export default {
    components: {
        HeaderCard,
        AppGrafico
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            conjuntosDeDados: [
                {
                    label: 'No Prazo',
                    key: 'dentroDoPrazo',
                    keyPorcentagem: 'porcentagemDentroDoPrazo',
                    cor: '#36A2EB'
                },
                {
                    label: 'Fora do Prazo',
                    key: 'foraDoPrazo',
                    keyPorcentagem: 'porcentagemForaDoPrazo',
                    cor: '#FF6384'
                }
            ],
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/dentro-e-fora-dos-prazos', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });

                this.dados = data.map((item) => ({
                    ...item,
                    quebra: this.formatarData(item.quebra)
                }));
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YY';
            const dataFormatada = moment.tz(data, 'UTC').locale('pt-br').format(formato);

            return dataFormatada.replace(/^./, dataFormatada[0].toUpperCase());
        }
    }
};
</script>
