<template>
    <div>
        <HeaderCard :loading="loading" tittle="Top 10 de agendamentos por cidade" @onLoad="load" />
        <div class="m-2">
            <DataTable :value="dados" scrollable scrollHeight="340px" dataKey="id">
                <Column field="cidade" header="Cidade" sortable style="width: 25%"></Column>
                <Column field="estado" header="Estado" sortable style="width: 5%"></Column>
                <Column field="quantidade" header="Agendamentos" sortable style="width: 25%"></Column>
            </DataTable>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/cidades-com-mais-agendamentos', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>
<style scoped>
:deep(::-webkit-scrollbar-track) {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
</style>
