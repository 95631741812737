<template>
    <div>
        <HeaderCard :loading="loading" tittle="Agendamentos por estado" @onLoad="load" />
        <div class="flex flex-row justify-content-center">
            <Chart type="pie" :data="stackedData" class="w-28rem" />
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    computed: {
        stackedData() {
            return {
                labels: this.getEstados(),
                datasets: [
                    {
                        data: this.getQuantidade(),
                        backgroundColor: this.getColor()
                    }
                ]
            };
        }
    },

    methods: {
        getEstados() {
            const estados = this.dados.map((d) => d.sigla);
            return estados;
        },
        getQuantidade() {
            const array = [];
            const siglaEstados = this.dados.map((d) => d.sigla);
            for (const siglaEstado of siglaEstados) {
                const arrayDadosQuantidade = this.dados.filter((d) => d.sigla == siglaEstado).map((p) => Number(p.quantidade));
                const soma = arrayDadosQuantidade.reduce((a, b) => a + b, 0);
                array.push(soma);
            }
            return array;
        },
        getColor() {
            const colorArray = [];
            const siglaEstados = this.dados.map((d) => d.sigla);
            for (const siglaEstado of siglaEstados) {
                if (siglaEstado == 'RO') {
                    colorArray.push('#f5f056');
                    continue;
                } else if (siglaEstado == 'AC') {
                    colorArray.push('#00ffae');
                    continue;
                } else if (siglaEstado == 'AM') {
                    colorArray.push('#ff001e');
                    continue;
                } else if (siglaEstado == 'RR') {
                    colorArray.push('#2200ff');
                    continue;
                } else if (siglaEstado == 'PA') {
                    colorArray.push('#1500ff');
                    continue;
                } else if (siglaEstado == 'AP') {
                    colorArray.push('#00ff09');
                    continue;
                } else if (siglaEstado == 'TO') {
                    colorArray.push('#ff002f');
                    continue;
                } else if (siglaEstado == 'MA') {
                    colorArray.push('#4189e6');
                    continue;
                } else if (siglaEstado == 'PI') {
                    colorArray.push('#3D9970');
                    continue;
                } else if (siglaEstado == 'CE') {
                    colorArray.push('#ff007b');
                    continue;
                } else if (siglaEstado == 'RN') {
                    colorArray.push('#00ff7b');
                    continue;
                } else if (siglaEstado == 'PB') {
                    colorArray.push('#33ff00');
                    continue;
                } else if (siglaEstado == 'PE') {
                    colorArray.push('#00fff7');
                    continue;
                } else if (siglaEstado == 'AL') {
                    colorArray.push('#04ff00');
                    continue;
                } else if (siglaEstado == 'SE') {
                    colorArray.push('#ff9500');
                    continue;
                } else if (siglaEstado == 'BA') {
                    colorArray.push('#c800ff');
                    continue;
                } else if (siglaEstado == 'MG') {
                    colorArray.push('#aeff00');
                    continue;
                } else if (siglaEstado == 'ES') {
                    colorArray.push('#ff8c00');
                    continue;
                } else if (siglaEstado == 'RJ') {
                    colorArray.push('#ffea00');
                    continue;
                } else if (siglaEstado == 'SP') {
                    colorArray.push('#36A2EB');
                    continue;
                } else if (siglaEstado == 'PR') {
                    colorArray.push('#e594be');
                    continue;
                } else if (siglaEstado == 'SC') {
                    colorArray.push('#FF6384');
                    continue;
                } else if (siglaEstado == 'RS') {
                    colorArray.push('#FFCD56');
                    continue;
                } else if (siglaEstado == 'MS') {
                    colorArray.push('#2600ff');
                    continue;
                } else if (siglaEstado == 'MT') {
                    colorArray.push('#00b2ff');
                    continue;
                } else if (siglaEstado == 'GO') {
                    colorArray.push('#62e641');
                    continue;
                } else if (siglaEstado == 'DF') {
                    colorArray.push('#4BC0C0');
                    continue;
                }
            }
            return colorArray;
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-clientes/estados', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>
