<template>
    <AppMultiselect
        v-model="usuarioComputed"
        :service="service"
        optionLabel="name"
    />
</template>

<script setup>
  import { computed, defineEmits, ref, onMounted } from 'vue';
  import BaseService from '../../../services/BaseService';
  const emit = defineEmits(['update:modelValue']);
  const usuario = ref(null);
  const service = ref(null);
  const usuarioComputed = computed({
      get: () => usuario.value,
      set: (newValue) => {
        emit('update:modelValue', newValue);
      },
  });

  onMounted(() => {
    service.value = new BaseService('/users/por-agendamentos');
  })
</script>